import React from 'react'
import Layout from '../components/layout'

const Pointwebcastlive = ({ location }) => {
  return (
    <>
    <Layout location = {location}>
    <section className='w-full p-8 bg-indigo-100'>
        <div className=' mx-auto my-auto'>
            <div className="flex flex-col items-center justify-center w-full">
            <img className='mt-2 mb-8' src="https://gatsycms.vantagecircle.com/wp-content/uploads/2022/03/Vantage_Points_Logo_Withchester-01.png" alt="Vantage Point Webcast Logo" height="280" width="200"/>
                <div className="w-7/12 md:w-3/12 xl:w-2/12 2xl:w-2/12 flex justify-start px-9 py-2 xl:py-2 bg-indigo-50 rounded-full relative" style = {{ boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
                    <h2 className="text-purple-100 w-full">Episode</h2>
                    <div className="w-9 h-9 flex items-center justify-center bg-orange text-white rounded-full absolute right-1 bottom-1">3</div>
                </div>
                <h2 className="font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-left mt-9 mb-6 text-purple-100 text-center">Empathy Boosts Productivity: How do we build an Empathetic Culture?</h2>
                <p className="max-w-6xl text-lg md:text-xl xl:text-2xl text-left text-purple-100 text-center" style={{ lineHeight: '2.2rem'}}>Adrian Gostick and Chester Elton along with some of the finest minds in HR will talk about empathy no longer being a soft skill, but a hard skill in the workplace.</p>
            </div>
        </div>
    </section>

    <section className='w-full flex justify-center mt-8 px-1 lg:px-3'>
        <div className = "w-11/12 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-7 justify-center items-center p-5 lg:p-10 bg-indigo-100 rounded-lg shadow">
            <div className = "w-full flex flex-col text-center lg:text-left xl:ml-6 2xl:ml-0 justify-center lg:items-start items-center">
                <h2 className = "text-center lg:text-left text-3xl xl:text-3xl text-purple-100 my-5 tracking-wide">Reimagining employee recognition through the <span className = "text-purple-100 font-bold">AIR<span>e</span> Framework</span></h2>
                <div className = "grid grid-cols-1 justify-center items-center lg:justify-start w-full md:w-4/6 lg:w-5/6 2xl:w-3/6 my-3">
                    <button className = "vc-white-btn">Download Whitepaper</button>
                </div>
            </div>
            <div class="w-full flex justify-center lg:justify-end items-center my-9 lg:my-0">
                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/AIRe-Illus-1.png" alt="Vantage Rewards" width = "550" height = "109"/>
            </div>
        </div>
    </section>

    <section className='w-full my-4 lg:my-8 px-8 bg-white flex justify-center items-center'>
        <div className='flex justify-center items-center pointweb'>
            <iframe className= 'rounded-lg border-4 border-white my-4' style = {{ boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.25)' }} src="https://www.youtube.com/embed/mKrHqgk8nGE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </section>

    <section className='w-full mb-8 mt-0 lg:mb-20 px-4 md:px-10 bg-white flex justify-center items-center'>
        <div className='flex justify-center items-center'>
            <a href='https://www.vantagecircle.com/hr-academy/whitepapers/aire-framework/'><img className='border-4 border-white rounded-lg' style = {{ boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.25)' }} src='https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/Artboard-1-copy-8.png' loading='lazy' /></a>
        </div>
    </section>

    <section id="guestspeakers" class="flex flex-col place-items-center w-full relative">
        <h2 class="font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-left w-11/12 md:w-10/12 xl:w-10/12 text-gray-500 py-9 text-center">Guest Speakers</h2>
        <div class="w-full flex flex-col items-center justify-center bg-indigo-100 py-16 relative z-1 overflow-hidden">    
            <div class="grid grid-cols-1 md:grid-cols-3 gap-20 justify-center w-full md:w-5/6 lg:w-11/12 xl:w-10/12 pl-5 md:pl-0 lg:pl-8 xl:px-0 relative z-1">
                <div class="flex justify-start lg:justify-center">
                    <div class="flex flex-col">
                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Andy-Holmes-Guest-Speaker-Vantage-Point-Webcasts.png" alt="Andy Holmes, Guest Speaker, Vantage Point Webcast USA" width="200" height="200" />
                        <h2 class="font-bold text-gray-100 text-xl md:text-2xl xl:text-3xl 2xl:text-4xl py-3">Andy Holmes</h2>
                        <p class="text-gray-10">Speaker, Coach &amp; Former Global Head of Wellbeing at Reckitt</p>
                        <p class="text-gray-100 italic">Driven by his personal experiences, knowledge &amp; passion for people, Andy takes a strategic approach to ‘human capacity’, working with an extensive network of specialists to restructure personal &amp; organizational approaches to wellbeing, leadership &amp; performance. With his background as a professional sportsman, his degree in physiology &amp; decades of experience heading-up capability &amp; leadership, Andy brings together the science of holistic wellbeing, along with cognition &amp; leadership development to enable cultural change, unlock D&amp;I, collaboration &amp; innovation, to drive personal growth &amp; business performance.</p>
                    </div>
                </div>
                <div class="flex justify-start lg:justify-center ">
                    <div class="flex flex-col">
                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/Zeenat-Ladhani-1.png" alt="Zeenat Ladhani, Guest Speaker, Vantage Point Webcast USA" width="200" height="200" />
                        <h2 class="font-bold text-gray-100 text-xl md:text-xl xl:text-3xl 2xl:text-4xl py-3">Zeenat Ladhani</h2>
                        <p class="text-gray-10">Head of HR, Global Oncology Business, Daiichi Sankyo</p>
                        <p class="text-gray-100 italic">Zeenat Ladhani is the President of NextGen Talent Search, a boutique firm focused on diverse recruitment, competitive intelligence and assessment of tools, technology and process automation for clients in the Biotech, Pharma and Healthcare/Wellness sectors. Prior to NextGen, Zeenat worked at Bristol Myers Squibb (BMS) and Thermo Fisher Scientific. Zeenat is a results driven leader with strong business acumen, creative thinking and keen passion and understanding of data to drive business insights. She is skilled at championing and leading next generation recruiting practices that improve organizational capability through digital transformation. </p>
                    </div>
                </div>
                <div class="flex justify-start lg:justify-center ">
                    <div class="flex flex-col">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/Susan-Winchester-1.png" width="200" height="200" alt="Susan Winchester, Guest Speaker, Vantage Point Webcast USA" />
                        <h2 class="font-bold text-gray-100 text-xl md:text-xl xl:text-3xl 2xl:text-4xl py-3">Susan Winchester</h2>
                        <p class="text-gray-10">SVP, Chief Human Resources Officer for Applied Materials</p>
                        <p class="text-gray-100 italic">Susan is a Leader, Innovator, Talent Advocate, Value Creator, Keynote Speaker, Equestrian, Author of  “Healing at Work: A Guide to Using Career Conflicts to Overcome Your Past and Build the Future You Deserve, Energizer and Eternal Optimizer. Susan has spent 25+ years creating value for organizations in a variety of industries by clarifying corporate strategy to align everyone, building leadership capability, assessing and developing talent and advocating a corporate culture of inclusion and alignment where every individual can contribute their best.</p>
                    </div>
                </div>
            </div>
            <svg class="absolute -right-10 -top-9 -z-1 lace-wrapper1 flex opacity-40 md:opacity-100" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 442.6 523.6">
                <path class="curve" d="M30,32.8c0,0,81.2,231.5,177.8,182.2c39.7-20.2,47.5-53.6,47.8-75.4c0.2-13.9-4.1-27.5-12.4-36.2
            c-5-5.3-11.9-9.2-20.6-8.2C189.2,99,144.1,219.4,190.6,232c32.8,8.9,81.5-9.7,81.5-9.7s123.1-43.9,111.1,97.2
            c-9.3,56.3-25.2,62.3-38.8,99.5c-16.1,44.1,3.7,113.6,63.7,53.1"></path>
            </svg>
        </div>
    </section>

    <section>
    <div class="container max-w-7xl mx-auto px-6 py-10 md:py-20" id="hosts">
        <h2 class="font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-center text-gray-500 py-9">
            About the Hosts
        </h2>
        <div class="max-w-3xl mx-auto mt-10 grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                    <div class="relative">
                        <a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/chester-elton-advisors.png" alt="Chester Elton, Advisor, Vantage Circle" width="366" height="422" />
                            </a><a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                            <div class="text-center absolute bottom-0 p-4">
                                <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Chester Elton</p>
                                <p class="text-white text-sm sm:text-base mb-2">#1 Bestselling Business Author, Leadership Expert, Organizational Culture Expert, Employee Engagement and Teamwork Expert, Global Keynote Speaker.</p>
                            </div>
                        
                    </div>
                    <div class="absolute adv-detail">
                            <p class="text-white text-sm sm:text-base mb-0">Chester Elton is a #1 Bestselling Business Author, #4 among the world’s top leadership experts and #2 among the world’s top organizational culture experts. He is a member of Marshall Goldsmith 100 Coaches, an Executive Coach, Keynote Speaker, and Founder of the #findyourgratitude Community. In the last two decades, Chester has helped some of the world’s most successful businesses engage their employees to execute on strategy, vision, and values.</p>
                    </div>
            </div>
            <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                    <div class="relative">
                        <a href="https://www.linkedin.com/in/adriangostick/" target="_blank" rel="noopener">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/Adrian-advisors.png" alt="Adrian Gostick, Advisor, Vantage Circle" width="366" height="422" />
                            </a><a href="https://www.linkedin.com/in/adriangostick/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                            <div class="text-center absolute bottom-0 p-4">
                                <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Adrian Gostick</p>
                                <p class="text-white text-sm sm:text-base mb-2">#1 New York Times bestselling author, Corporate Culture Expert, Engagement Expert, Leadership Expert, Managing Change Expert, Global Keynote Speaker.</p>
                            </div>
                        
                    </div>
                    <div class="absolute adv-detail">
                            <p class="text-white text-sm sm:text-base mb-0">Adrian Gostick is a global thought leader in the fields of corporate culture, leadership, and engagement. He is founder of the workplace training and consulting company The Culture Works and author of the New York Times, USA Today and Wall Street Journal bestsellers All In, The Carrot Principle, Leading with Gratitude, and Anxiety at Work. In 2021, Adrian was ranked as a top 10 Global Guru in Leadership and Organizational Culture. He is a member of Marshall Goldsmith’s MG100 ‘Pay it Forward’ Coaching Cohort.</p>
                    </div>
            </div>
        </div>
    </div>
</section>
                <section class="w-full flex flex-col place-items-center my-8 xl:mt-20 xl:mb-0" id="about">
                    <div class="flex flex-col place-items-center text-center w-9/12 mb-9">
                        <h2 class="text-gray-500 text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl font-bold">About <span class="text-purple-500">Vantage Circle</span></h2>
                        <p class="w-5/6 my-7 text-gray-500 text-lg md:text-xl xl:text-2xl" style={{ lineHeight: '2.2rem' }}>We believe in empowering today’s workplaces by offering them rewards and recognition, employee benefits, wellness, and feedback solutions. Our goal is to forge meaningful connections between employees and their organizations. We keep a global perspective to ensure that your employee engagement goals are fulfilled.</p>
                    </div>
                </section>
                <div className='w-full flex justify-center mb-20'>
                <a href="https://www.vantagecircle.com/" class="text-gray-100 bg-newOrange py-2 px-3 xl:px-4 2xl:px-5 rounded mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg">Explore More</a>
                </div>
</Layout>
    </>
  )
}

export default Pointwebcastlive